<template>
  <v-container>
    <div class="mb-10">
      <h3 class="mb-10">{{ $t("message.schedule-management") }}</h3>
      <br />
      <FullCalendar :options="config" class="full-calendar" />
    </div>
    <!-- <div class="me-3 mt-">
      <v-card class="d-flex flex-row-reverse" flat tile>
        <v-btn rounded color="indigo" dark width="200" @click="submitPublish">
          {{ $t("message.publish") }}
        </v-btn>
      </v-card>
    </div> -->
  </v-container>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import jaLocale from '@fullcalendar/core/locales/ja';
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ScheduleMenu",
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      menu: [],
      key: null,
      type: 1,
      published_at: new Date()
    };
  },
  watch: {
    menus: function(newVal, oldVal) {
      this.menus;
    },
  },
  computed: {
    // events() {
    //   return this.$store.getters["schedule_module/events"];
    // },
    menus() {
      return this.$store.getters["schedule_module/getMenu"];
    },
    config() {
      return {
        ...this.configOptions,
        ...this.eventHandler,
      };
    },
    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.menus,
        weekends: true,
        locale: 'ja',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth",
        },
        initialView: "dayGridMonth",
      };
    },
    eventHandler() {
      return {
        dateClick: this.onDateClick,
        // eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
      };
    },
  },
  created() {
    this.dispatchCreateMenu();
  },
  methods: {
    ...mapActions("schedule_module", [
      "createEvent",
      "updateEvent",
      "deleteEvent",
    ]),
    // submitPublish(){
    //    this.$store.dispatch("schedule_module/publish", {
    //      type: this.type,
    //      published_at:   moment(this.published_at).format('YYYY-MM-DD') 
    //    })
    // },

    async dispatchCreateMenu() {
      let res = await this.$store.dispatch("schedule_module/createMenu");
      return res;
    },

    onDateClick(payload) {
      this.$router.push(`/schedule/edit/${payload.dateStr}`);

      // const title = prompt("Please enter a new title for your event");
      // if (!title) {
      //   return;
      // }

      // const id = (this.events.length + 1) * 10;
      // const { start, end, date, allDay } = payload;

      // return this.createEvent({
      //   id,
      //   title,
      //   date,
      //   start,
      //   end,
      //   allDay,
      // });
    },

    // onEventClick({ event }) {
    //   const confirmed = confirm(
    //     `Are you sure you want to delete the event '${event.title}' ${event.id}?`
    //   );
    //   if (!confirmed) {
    //     return;
    //   }
    //   return this.deleteEvent(event.id);
    // },

    onEventDrop({ event }) {
      return this.updateEvent(event);
    },
  },
};
</script>

<style>
.full-calendar .fc-button-group .fc-button {
  padding: 5px 10px 5px 10px;
}
a {
  text-decoration: none;
}
.fc-icon {
  display: inline;
}
.fc-daygrid-day:hover {
  background-image: url("../assets/pencil (3).png");
  background-color: transparent;
  background-position: left top;
  background-repeat: no-repeat;
  /* -webkit-transform: scale(3,1); */
  /* background-size: contain; */
  /* padding-left: 50px; */
  /* line-height:30px */
  /* background-origin:border-box; */
}
.fc-daygrid-day:hover .fc-daygrid-day-events {
  /* z-index: -1; */
}
.fc-daygrid-day:hover .fc-daygrid-day-number {
  font-size: 16px;
  /* color: whitesmoke; */
}
.fc-daygrid-day:hover .fc .fc-daygrid-day.fc-day-today {
  background-color: rgb(152, 151, 151);
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #3f51b5;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: whitesmoke;
}
.fc .fc-daygrid-day.fc-day-today .fc-more-link {
  color: whitesmoke;
}
.fc-highlight {
  background: transparent !important;
}
thead {
  background: #3949ab;
}
thead a {
  color: white !important;
}
</style>
